import { Component, ElementRef, OnDestroy, OnInit, forwardRef, input, output, viewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject, debounceTime, distinctUntilChanged, filter, fromEvent, map, takeUntil } from 'rxjs';

type AutocompleteValue<T> = T extends object ? T[keyof T] : T;

/**
 * A reusable autocomplete component that supports both simple (string/number) and complex (object) values.
 * It implements ControlValueAccessor for form integration and provides flexible configuration options
 * for displaying and selecting values.
 *
 * @example Simple usage with string values:
 * ```html
 * <hmt-autocomplete
 *   label="Fruits"
 *   [options]="['Apple', 'Banana', 'Cherry']"
 *   [formControl]="fruitControl">
 * </hmt-autocomplete>
 * ```
 *
 * @example Complex usage with object values:
 * ```html
 * <hmt-autocomplete
 *   label="Fruits"
 *   [options]="fruits"
 *   [formControl]="fruitControl"
 *   [displayWith]="(fruit) => fruit.name"
 *   [valueWith]="(fruit) => fruit.id">
 * </hmt-autocomplete>
 * ```
 */
@Component({
  selector: 'hmt-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent<OptionType, ValueType = OptionType>
  implements OnInit, OnDestroy, ControlValueAccessor
{
  /** Array of options to display in the autocomplete dropdown */
  options = input.required<OptionType[]>();

  /** For object arrays, specifies which field to display in the dropdown. Cannot be used with displayWith */
  displayField = input<keyof OptionType | undefined>(undefined);

  /** For object arrays, specifies which field to use as the value. Cannot be used with valueWith */
  valueField = input<keyof OptionType | undefined>(undefined);

  /** Placeholder text for the input field */
  placeholder = input('');

  /** Label text displayed above the input field */
  label = input<string | undefined>(undefined);

  /** Whether the field is required */
  required = input(false);

  /** Delay in milliseconds before filtering occurs after user input */
  debounceTime = input(300);

  /** Material form field appearance */
  appearance = input<'outline' | 'fill'>('outline');

  /** Material form field subscript sizing */
  subscriptSizing = input<'dynamic' | 'fixed'>('dynamic');

  /** Function to determine how to display an option. Cannot be used with displayField */
  displayWith = input<((value: OptionType) => string) | null>(null);

  /** Function to determine the value of an option. Cannot be used with valueField */
  valueWith = input<((option: OptionType) => ValueType) | null>(null);

  /** Function to determine the option from a value. Cannot be used with valueField and must be used with valueWith to make sure the component can figure out the initial option if value is set in the form control */
  getOptionFromValue = input<((value: ValueType) => OptionType) | null>(null);

  /** Whether to disable automatic filtering (useful for server-side filtering) */
  manualFiltering = input(false);

  /** Whether to require a selection from the dropdown or can use the input value as the value in control, cannot be used together with object arrays as the options
   * @example
   * ```html
   * <hmt-autocomplete
   *   [options]="fruits"
   *   [requireSelection]="false"
   *   [formControl]="fruitControl" />
   * ```
   * Here users will be able to enter any arbitrary text and it will be set as the form control value. Filtering will still work, but unless user selects an option from the dropdown, the form will retain the text in search field.
   *
   */
  requireSelection = input(true);

  /** Emits when an option is selected from the dropdown */
  optionSelected = output<OptionType>();

  /** Emits when the search text changes, useful for implementing server-side filtering */
  searchChange = output<string>();

  inputElement = viewChild<ElementRef<HTMLInputElement>>('inputElement');
  matAutocomplete = viewChild<MatAutocomplete>('auto');

  // searchControl = new FormControl<string>('');
  filteredOptions: OptionType[] = [];
  private destroy$ = new Subject<void>();
  private onChange: (value: ValueType) => void = () => {};
  private onTouched: () => void = () => {};

  ngOnInit() {
    this.setupSearchSubscription();
    this.filteredOptions = this.options();

    if (this.requireSelection() && this.options().length > 0 && typeof this.options()[0] === 'object') {
      throw new Error(
        'requireSelection cannot be used with object arrays as options. This would allow raw search text to be set as the form control value, ' +
          'which is incorrect since object arrays require proper value mapping through valueField or valueWith.\n\n' +
          'Instead, always require selection when using object arrays. Examples:\n' +
          '1. Simple object array usage (always requires selection):\n' +
          '   <hmt-autocomplete\n' +
          '     [options]="fruits"\n' +
          '     [displayField]="\'name\'"\n' +
          '     [valueField]="\'id\'"\n' +
          '     [formControl]="fruitControl" />\n\n' +
          '2. For free text input, use string arrays instead:\n' +
          '   <hmt-autocomplete\n' +
          '     [options]="fruitNames"\n' +
          '     [requireSelection]="false"\n' +
          '     [formControl]="fruitControl" />'
      );
    }

    if (this.displayWith() && this.displayField()) {
      throw new Error(
        'Only one of displayWith or displayField should be provided. Examples:\n' +
          '1. [displayWith]="(item) => item.name"\n' +
          '2. displayField="name"'
      );
    }

    if (this.valueWith() && this.valueField()) {
      throw new Error(
        'Only one of valueWith or valueField should be provided. Examples:\n' +
          '1. [valueWith]="(item) => item.id"\n' +
          '2. valueField="id"'
      );
    }

    // if (this.getOptionFromValue() && this.valueField()) {
    //   throw new Error(
    //     'getOptionFromValue and valueField should not be provided together. Examples:\n' +
    //       '1. [getOptionFromValue]="(value) => options.find(option => option.id === value)"\n' +
    //       '2. [valueField]="id"'
    //   );
    // }

    if ((!this.getOptionFromValue() && this.valueWith()) || (this.valueField() && !this.getOptionFromValue())) {
      throw new Error(
        'getOptionFromValue must be provided with valueWith or valueField. Examples:\n' +
          '1. [getOptionFromValue]="(value) => options.find(option => option.id === value)"\n' +
          '2. [valueWith]="(option) => option.id"'
      );
    }

    if (this.options().length > 0 && typeof this.options()[0] === 'object') {
      if (!this.displayWith() && !this.displayField()) {
        throw new Error(
          'Either displayWith or displayField must be provided for object arrays. Examples:\n' +
            '1. [displayWith]="(item) => item.name"\n' +
            '2. displayField="name"'
        );
      }

      if (!this.valueWith() && !this.valueField()) {
        throw new Error(
          'Either valueWith or valueField must be provided for object arrays. Examples:\n' +
            '1. [valueWith]="(item) => item.id"\n' +
            '2. valueField="id"'
        );
      }
    }

    if (this.options().length > 0 && (typeof this.options()[0] === 'string' || typeof this.options()[0] === 'number')) {
      if (this.displayWith() || this.displayField()) {
        throw new Error(
          'displayWith and displayField should not be used with string/number arrays.\n' +
            'These are only needed when working with object arrays.\n' +
            'For string/number arrays, the values are displayed directly.'
        );
      }

      if (this.valueWith() || this.valueField()) {
        throw new Error(
          'valueWith and valueField should not be used with string/number arrays.\n' +
            'These are only needed when working with object arrays.\n' +
            'For string/number arrays, the values are used directly.'
        );
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async writeValue(value: ValueType | null): Promise<void> {
    if (!value) {
      this.inputElement().nativeElement.value = '';
      return;
    }

    let option: OptionType | undefined;

    // For simple string/number arrays
    if (typeof this.options()[0] === 'string' || typeof this.options()[0] === 'number') {
      option = value as unknown as OptionType;
      if (option) {
        if (!this.requireSelection() || this.options().includes(option)) {
          this.inputElement().nativeElement.value = this.getDisplayValue(option);
        }
        if (this.requireSelection() && !this.options().includes(option)) {
          console.warn(
            'Given initial value is not in the options array and requireSelection is true. This will not be reflected in the form control value.'
          );
          this.onChange(null);
        }
      }
      return;
    }

    // For object arrays using valueField
    if (this.valueField() && this.getOptionFromValue()) {
      option = await this.getOptionFromValue()!(value);
      if (option) {
        const displayValue = this.getDisplayValue(option);
        this.inputElement().nativeElement.value = displayValue;
      }
      return;
    }

    // For object arrays using valueWith and getOptionFromValue
    if (this.valueWith() && this.getOptionFromValue()) {
      const option = await this.getOptionFromValue()!(value);
      if (option) {
        const displayValue = this.getDisplayValue(option);
        this.inputElement().nativeElement.value = displayValue;
      }
      return;
    }
  }

  registerOnChange(fn: (value: ValueType) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled
      ? (this.inputElement().nativeElement.disabled = true)
      : (this.inputElement().nativeElement.disabled = false);
  }

  onOptionSelected(option: OptionType): void {
    let value: ValueType = undefined;

    if (typeof option === 'string' || typeof option === 'number') {
      value = option as ValueType;
    }

    if (this.valueField() && typeof option === 'object') {
      value = option[this.valueField()!] as ValueType;
    }

    if (this.valueWith()) {
      value = this.valueWith()!(option) as ValueType;
    }

    this.onChange(value);
    this.optionSelected.emit(option);
    const displayValue = this.getDisplayValue(option);
    this.inputElement().nativeElement.value = displayValue;
  }

  private setupSearchSubscription(): void {
    let valueChanges$ = fromEvent(this.inputElement().nativeElement, 'input').pipe(
      map(event => (event.target as HTMLInputElement).value)
    );

    if (this.manualFiltering()) {
      valueChanges$ = valueChanges$.pipe(debounceTime(this.debounceTime()));
    }

    valueChanges$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        filter(
          searchText =>
            searchText !== null && searchText !== undefined && searchText !== '' && typeof searchText === 'string'
        )
      )
      .subscribe(searchText => {
        if (searchText === null) return;

        this.searchChange.emit(searchText);

        if (!this.manualFiltering()) {
          this.filterOptions(searchText);
        }

        if (!this.requireSelection()) {
          this.onChange(searchText as ValueType);
        }
      });
  }

  private filterOptions(searchText: string): void {
    if (!searchText) {
      this.filteredOptions = this.options();
      return;
    }

    this.filteredOptions = this.options().filter(option => {
      const displayValue = this.getDisplayValue(option).toLowerCase();
      return displayValue.includes(searchText.toLowerCase());
    });
  }

  getDisplayValue(option: OptionType | AutocompleteValue<OptionType>): string {
    let displayValue = '';

    if (this.displayWith()) {
      displayValue = this.displayWith()!(option as OptionType);
    }

    if (typeof option === 'string' || typeof option === 'number') {
      displayValue = String(option);
    }

    if (this.displayField() && typeof option === 'object') {
      const value = (option as OptionType & object)[this.displayField()!];
      displayValue = String(value);
    }

    return displayValue;
  }

  getValue(option: OptionType): ValueType {
    let value: ValueType = undefined;

    if (typeof option === 'string' || typeof option === 'number') {
      value = option as ValueType;
    }

    if (this.valueField() && typeof option === 'object') {
      value = option[this.valueField()!] as ValueType;
    }

    if (this.valueWith()) {
      value = this.valueWith()!(option) as ValueType;
    }

    return value;
  }

  onBlur(): void {
    this.onTouched();
  }

  getProperOptions(): OptionType[] {
    if (!this.manualFiltering() && this.inputElement().nativeElement.value !== '') {
      return this.filteredOptions;
    }

    return this.options();
  }
}
