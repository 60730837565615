import { gql } from 'apollo-angular';

export const TRIGGER_CONTRACT_BULK_DOWNLOAD = gql`
  query Query($viewType: String!) {
    triggerContractBulkDownload(viewType: $viewType)
  }
`;

export interface TriggerContractBulkDownloadResponse {
  triggerContractBulkDownload: any;
}
