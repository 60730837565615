import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';
import {
  FIND_LONG_TERM_CONTRACT_BY_ID,
  FindLongTermRatesContractByIdQueryResponse,
} from '../queries/fetch-longterm-contract-by-id.query';

import {
  FIND_LONG_TERM_RATES_CONTRACT_BY_ID,
  FindLongTermRatesContractByIdResponse,
} from '../queries/find-long-term-rates-contract-by-id.query';
import {
  FIND_PURCHASE_LONG_TERM_RATES_CONTRACTS,
  FindPurchaseLongTermRatesContractsQueryResponse,
  FindPurchaseLongTermRatesContractsQueryVariables,
} from '../queries/find-purchase-long-term-rates-contracts.query';
import {
  FIND_SALES_LONG_TERM_RATES_CONTRACTS,
  FindSalesLongTermRatesContractsQueryResponse,
  FindSalesLongTermRatesContractsQueryVariables,
} from '../queries/find-sales-long-term-rates-contracts.query';
import {
  TRIGGER_CONTRACT_BULK_DOWNLOAD,
  TriggerContractBulkDownloadResponse,
} from '../queries/trigger-contract-bulk-download.query';
import { ContractViewType } from '../types/long-term-contract-download.type';
@Injectable({
  providedIn: 'root',
})
export class LongTermContractsService {
  gqlClient = inject(Apollo);

  findLongTermRatesContractById(longTermContractId: string) {
    return this.gqlClient
      .query<FindLongTermRatesContractByIdResponse>({
        query: FIND_LONG_TERM_RATES_CONTRACT_BY_ID,
        variables: { longTermContractId },
      })
      .pipe(map(result => result.data.findLongTermRatesContractById));
  }

  findSalesLongTermRatesContracts(variables: FindSalesLongTermRatesContractsQueryVariables) {
    return this.gqlClient
      .query<FindSalesLongTermRatesContractsQueryResponse>({
        query: FIND_SALES_LONG_TERM_RATES_CONTRACTS,
        variables: variables,
      })
      .pipe(map(result => result.data.findSalesLongTermRatesContracts));
  }

  findPurchaseLongTermRatesContracts(variables: FindPurchaseLongTermRatesContractsQueryVariables) {
    return this.gqlClient
      .query<FindPurchaseLongTermRatesContractsQueryResponse>({
        query: FIND_PURCHASE_LONG_TERM_RATES_CONTRACTS,
        variables: variables,
      })
      .pipe(map(result => result.data.findPurchaseLongTermRatesContracts));
  }

  findLongTermContractById(id: string) {
    return this.gqlClient
      .query<FindLongTermRatesContractByIdQueryResponse>({
        query: FIND_LONG_TERM_CONTRACT_BY_ID,
        variables: { longTermContractId: id },
      })
      .pipe(map(result => result.data.findLongTermRatesContractById));
  }

  triggerContractBulkDownload(viewType: ContractViewType) {
    return this.gqlClient
      .query<TriggerContractBulkDownloadResponse>({
        query: TRIGGER_CONTRACT_BULK_DOWNLOAD,
        variables: { viewType },
      })
      .pipe(
        map(result => {
          const response = result.data.triggerContractBulkDownload;
          return response;
        })
      );
  }
}
