<mat-form-field [appearance]="appearance()" class="full-width" [subscriptSizing]="subscriptSizing()">
  @if (label()) {
    <mat-label>{{ label() }}</mat-label>
  }
  <input
    [attr.aria-label]="label() || placeholder()"
    type="text"
    matInput
    #inputElement
    [placeholder]="placeholder()"
    [required]="required()"
    [matAutocomplete]="auto"
    (blur)="onBlur()" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event.option.value)">
    @for (option of getProperOptions(); track option) {
      <mat-option [value]="option">
        {{ getDisplayValue(option) }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
