import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EnhancedDatagridTransitTimeEditorParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'hmt-enhanced-datagrid-transit-time-editor',
  templateUrl: './enhanced-datagrid-transit-time-editor.component.html',
  styleUrls: ['./enhanced-datagrid-transit-time-editor.component.scss'],
})
export class EnhancedDatagridTransitTimeEditorComponent<TData = Record<string, unknown>>
  implements ICellEditorAngularComp
{
  params!: EnhancedDatagridTransitTimeEditorParams<TData>;
  initialValue!: number;

  public timeForm = new FormGroup({
    days: new FormControl<number>(0),
    hours: new FormControl<number>(0),
  });

  private readonly MS_PER_DAY = 24 * 60 * 60 * 1000;
  private readonly MS_PER_HOUR = 60 * 60 * 1000;

  agInit(params: EnhancedDatagridTransitTimeEditorParams<TData>): void {
    this.params = params;
    this.initialValue = Number(params.data[params.colDef.field as string]) || 0;

    // Convert milliseconds to days and hours
    const totalDays = Math.floor(this.initialValue / this.MS_PER_DAY);
    const remainingMs = this.initialValue % this.MS_PER_DAY;
    const totalHours = Math.ceil(remainingMs / this.MS_PER_HOUR);

    this.timeForm.patchValue({
      days: totalDays,
      hours: totalHours,
    });

    if (
      params.calculateDefaultFrom &&
      (!params.data[params.colDef.field as string] || params.data[params.colDef.field as string] === 0)
    ) {
      const fromValue = new Date(this.params.data[params.calculateDefaultFrom.from]);
      const toValue = new Date(this.params.data[params.calculateDefaultFrom.to]);
      console.log('fromValue', fromValue);
      console.log('toValue', toValue);

      if (fromValue && toValue) {
        const diffMs = Math.abs(toValue.getTime() - fromValue.getTime());
        const days = Math.floor(diffMs / this.MS_PER_DAY);
        const remainingMs = diffMs % this.MS_PER_DAY;
        const hours = Math.ceil(remainingMs / this.MS_PER_HOUR);

        this.timeForm.patchValue({
          days,
          hours,
        });
      }
    }
  }

  getValue(): number {
    const { days, hours } = this.timeForm.value;
    const isDaysSet = typeof days === 'number';
    const isHoursSet = typeof hours === 'number';

    if (!isDaysSet && !isHoursSet) {
      return this.initialValue;
    }

    const daysMs = isDaysSet ? days * this.MS_PER_DAY : 0;
    const hoursMs = isHoursSet ? hours * this.MS_PER_HOUR : 0;

    return daysMs + hoursMs;
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      // const totalDays = Math.floor(this.initialValue / this.MS_PER_DAY);
      // const remainingMs = this.initialValue % this.MS_PER_DAY;
      // const totalHours = Math.ceil(remainingMs / this.MS_PER_HOUR);

      // this.timeForm.patchValue({
      //   days: totalDays,
      //   hours: totalHours,
      // });

      this.params.stopEditing();
    }
  }
}
