import { Component, computed, input } from '@angular/core';
import { LongTermRatesContract } from 'app/modules/contract-management/models/long-term-contract.model';

@Component({
  selector: 'hmt-long-term-contract-location-pair-summary',
  templateUrl: './long-term-contract-location-pair-summary.component.html',
  styleUrl: './long-term-contract-location-pair-summary.component.scss',
})
export class LongTermContractLocationPairSummaryComponent {
  locationPair =
    input.required<LongTermRatesContract['metaData']['serviceMetaData'][number]['locationPairs'][number]>();
  serviceId = input.required<string>();

  originCountry = computed(() => this.locationPair().originLocation.countryName);
  loadingPort = computed(() => this.locationPair().originLocation.locationName);
  destinationCountry = computed(() => this.locationPair().destinationLocation.countryName);
  unloadingPort = computed(() => this.locationPair().destinationLocation.locationName);
  transhipment = computed(() => (this.locationPair().transhipmentAllowed ? 'Allowed' : 'Not Allowed'));
  portOrAirport = computed(() => (this.serviceId() === 'OCEAN-FREIGHT' ? 'Port' : 'Airport'));
}
