<mat-accordion class="long-term-contract-location-pair">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title> {{ title() }} </mat-panel-title>
    </mat-expansion-panel-header>
    <hmt-long-term-contract-location-pair-summary [locationPair]="locationPair()" [serviceId]="serviceId()" />
    @for (vesselScheduleGroup of vesselScheduleGroups(); track vesselScheduleGroup.name) {
      <hmt-long-term-location-pair-vessel-schedule-table [vesselScheduleGroup]="vesselScheduleGroup" />
    }

    @if (flightScheduleGroups()) {
      <hmt-long-term-location-pair-flight-schedule-table [flightScheduleGroup]="flightScheduleGroups()" />
    }
    @if (allAdditionalCharges().length > 0) {
      <h3 class="fees-title">Fees</h3>
      <div *ngFor="let charge of allAdditionalCharges(); let i = index" class="additional-charge-row">
        <mat-form-field appearance="outline" class="charge-type-field" subscriptSizing="dynamic">
          <mat-label>Additional Charge</mat-label>
          <input type="text" matInput [readonly]="true" [value]="charge.description" />
        </mat-form-field>
        <div class="amount-currency-pair">
          <mat-form-field appearance="outline" class="amount-field" subscriptSizing="dynamic">
            <mat-label>Amount</mat-label>
            <input matInput type="number" [readonly]="true" [value]="charge.amount" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="currency-dropdown" subscriptSizing="dynamic">
            <mat-label>Currency</mat-label>
            <input matInput readonly [value]="charge.currencyCode" [readonly]="true" />
          </mat-form-field>
        </div>
      </div>
    } @else {
      <p class="no-fees-message">No Additional Fees</p>
    }
  </mat-expansion-panel>
</mat-accordion>
