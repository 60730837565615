<div class="general-details-container">
  <div class="col-2">
    <h3 class="rate-validity-period-heading">Port Pair</h3>
    <div class="row-2 content-style">
      <hmt-key-value
        [keyValue]="{ key: 'Country Of Origin', value: originCountry() }"
        style="width: 100%"></hmt-key-value>
      <hmt-key-value
        [keyValue]="{ key: 'Loading ' + portOrAirport(), value: loadingPort() }"
        style="width: 100%"></hmt-key-value>
      <hmt-key-value
        [keyValue]="{
          key: 'Country Of Destination',
          value: destinationCountry()
        }"
        style="width: 100%"></hmt-key-value>
      <hmt-key-value
        [keyValue]="{ key: 'Unloading ' + portOrAirport(), value: unloadingPort() }"
        style="width: 100%"></hmt-key-value>
      <hmt-key-value [keyValue]="{ key: 'Transhipment', value: transhipment() }" style="width: 100%"></hmt-key-value>
    </div>
  </div>
</div>
