import { Component, computed, input } from '@angular/core';
import { LongTermRatesContract } from 'app/modules/contract-management/models/long-term-contract.model';

@Component({
  selector: 'hmt-long-term-contract-location-pair',
  templateUrl: './long-term-contract-location-pair.component.html',
  styleUrls: ['./long-term-contract-location-pair.component.scss'],
})
export class LongTermContractLocationPairComponent {
  locationPair =
    input.required<LongTermRatesContract['metaData']['serviceMetaData'][number]['locationPairs'][number]>();

  index = input.required<number>();
  serviceId = input.required<string>();

  titlePrefix = computed(() => {
    switch (this.serviceId()) {
      case 'OCEAN-FREIGHT':
        return 'Port Pair';
      case 'AIR-FREIGHT':
        return 'Airport Pair';
      default:
        return 'Location Pair';
    }
  });

  title = computed(() => {
    return `${this.titlePrefix()} ${this.index() + 1}`;
  });

  vesselScheduleGroups = computed(() => {
    if (!this.locationPair().vesselsScheduleReferenceGroup) {
      return [];
    }

    return this.locationPair().vesselsScheduleReferenceGroup.map(group => ({
      name: `Vessel Schedule - ${group.shipmentMode}`,
      carrierReferences: group.carrierReference,
      shipmentMode: group.shipmentMode,
    }));
  });

  flightScheduleGroups = computed(() => {
    if (!this.locationPair().flightScheduleReferenceGroup) {
      return null;
    }

    return {
      name: `Flight Schedule - ${this.locationPair().flightScheduleReferenceGroup.shipmentMode}`,
      carrierReferences: this.locationPair().flightScheduleReferenceGroup.carrierReference,
      shipmentMode: this.locationPair().flightScheduleReferenceGroup.shipmentMode,
    };
  });

  baseAdditionalCharges = computed(() => {
    return this.locationPair().additionalCharges;
  });
  additionalChargesFromFlightOrVesselSchedules = computed(() => {
    const vesselCharges = (this.locationPair()?.vesselsScheduleReferenceGroup || []).reduce((acc, group) => {
      const groupCharges = group.carrierReference.reduce((carrierAcc, carrier) => {
        if (carrier.additionalCharges) {
          return [...carrierAcc, ...carrier.additionalCharges];
        }
        return carrierAcc;
      }, []);
      return [...acc, ...groupCharges];
    }, []);
    const flightCharges =
      this.locationPair()?.flightScheduleReferenceGroup?.carrierReference.reduce((acc, carrier) => {
        if (carrier.additionalCharges) {
          return [...acc, ...carrier.additionalCharges];
        }
        return acc;
      }, []) || [];
    const charges = [...vesselCharges, ...flightCharges];
    return charges;
  });
  allAdditionalCharges = computed(() => {
    return [...this.baseAdditionalCharges(), ...this.additionalChargesFromFlightOrVesselSchedules()];
  });
}
