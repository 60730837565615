<div class="full-contract-view">
  <hmt-view-long-term-contract
    [isInDialog]="false"
    [contractId]="contractId()"
    #viewContractRef></hmt-view-long-term-contract>
  <div class="footer">
    <!--TODO: For now disable terminate button always -->
    <div
      matTooltip="This feature is not available for now"
      matTooltipPosition="right"
      class="terminate-button-container">
      <button
        mat-stroked-button
        [disabled]="true"
        class="terminate-button btn-base btn-red-outline disabled-button"
        (click)="openTerminateTenderDialog()">
        Terminate Contract
      </button>
    </div>
    <div class="row">
      <button
        (click)="download()"
        mat-flat-button
        class="btn-base btn-gray-fill download-button"
        [ngClass]="{ disabled: (pdfReadyToDownload$ | async) === false }"
        [disabled]="(pdfReadyToDownload$ | async) === false">
        Download
      </button>
      <button (click)="goBack()" mat-flat-button class="btn-base btn-grey-outline back-button">Back</button>
    </div>
  </div>
</div>
